/**
 * External dependencies
 */
import { useState, useContext, useRef } from '@wordpress/element';
import { PropTypes } from 'prop-types';
import { __ } from '@wordpress/i18n';
import moment from 'moment';
import { some } from 'lodash';

/**
 * Internal dependencies
 */
import EventCalendarItemPopover from './popover';
import calendarContext from '../../blocks/bookings-calendar/context/context';
import { getIsRestrictedDay } from '../../includes/date-utils';

const EventCalendarItem = ( props ) => {
	const {
		showSoldOut,
	} = useContext( calendarContext );

	const [ showPopover, setShowPopover ] = useState( false );

	const linkRef = useRef( null );

	const handleCalendarItemMouseEnter = () => {
		setShowPopover( true );
		linkRef.current.focus();
	};

	const handleCalendarItemMouseLeave = () => {
		setShowPopover( false );
	};

	const getIsSoldOut = ( availability ) => {
		if ( 1 > availability.length ) {
			return false;
		}

		let booked = 0;
		let available = 0;

		/*
		 * To determine if all slots are
		 * booked and therefore soldout,
		 * we must check for 0 available and
		 * 1 or more booked.
		 */
		availability.forEach( ( slot ) => {
			booked += parseInt( slot.booked, 10 );
			available += parseInt( slot.available, 10 );
		} );

		if ( 0 === available && 0 < booked ) {
			return true;
		}

		return false;
	};

	const onDummyClick = ( e ) => {
		e.preventDefault();
	};

	const {
		isPreview,
		date,
		availability,
		product,
		product: {
			id,
			name,
			duration_type: durationType,
			price_html: priceHtml,
			has_persons: hasPersons,
			has_resources: hasResources,
			permalink,
		},
	} = props;
	const available = 0 < availability.length;
	const isSoldOut = getIsSoldOut( availability );
	const isRestrictedDay = getIsRestrictedDay( date, product );

	if ( isRestrictedDay || ( isSoldOut && ! showSoldOut ) ) {
		return null;
	}

	const now = moment();
	const isPastDate = ! some( availability, ( slot ) => {
		if ( 'day' === product.durationUnit ) {
			return now.isSameOrBefore( slot.date, 'day' );
		}
		return now.isBefore( slot.date );
	} );

	if ( available && ! isSoldOut && ! isPastDate ) {
		return (
			<div className={ 'wc-bookings-availability-calendar-day-item ' + ( showPopover && 'wc-bookings-availability-calendar-day-item__focused' ) }>
				{ /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
				<a href="#"
					className="wc-bookings-availability-calendar-day-item-title"
					onMouseEnter={ handleCalendarItemMouseEnter }
					onMouseLeave={ handleCalendarItemMouseLeave }
					onClick={ onDummyClick }
					ref={ linkRef }
				>
					{ name }
					{ showPopover &&
						<EventCalendarItemPopover
							date={ date }
							productId={ id }
							productName={ name }
							product={ product }
							availability={ availability }
							durationType={ durationType }
							price={ priceHtml }
							hasPersons={ hasPersons }
							hasResources={ hasResources }
							permalink={ permalink }
							isPreview={ isPreview }
						/>
					}
				</a>
			</div>
		);
	}

	if ( available && isSoldOut ) {
		return (
			<div className="wc-bookings-availability-calendar-day-item wc-bookings-availability-calendar-day-item__sold-out">
				<div className="wc-bookings-availability-calendar-day-item-title">
					{ name }
					{ ' ' }
					{ __( '(Sold out)', 'woocommerce-bookings-availability' ) }
				</div>
			</div>
		);
	}

	if ( available && isPastDate ) {
		return (
			<div className="wc-bookings-availability-calendar-day-item wc-bookings-availability-calendar-day-item__past-date">
				<div className="wc-bookings-availability-calendar-day-item-title">
					{ name }
					{ ' ' }
				</div>
			</div>
		);
	}

	return null;
};

EventCalendarItem.propTypes = {
	/**
	 * Availability data from wc_bookings_find_booking_slots ajax endpoint.
	 */
	availability: PropTypes.array.isRequired,
	/**
	 * Product information.
	 */
	product: PropTypes.object,
	/**
	 * Sets up component for in-editor preview if true.
	 */
	isPreview: PropTypes.bool,

	showSoldOut: PropTypes.bool,

	index: PropTypes.number,

	date: PropTypes.object.isRequired,
};

export default EventCalendarItem;
